/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import {
  List,
  ListItem,
  Typography,
  ListItemIcon,
  Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  navLink: {
    '&:hover': {
      color: theme.palette.primary.dark
    }
  },
  listItemText: {
    flex: '0 0 auto',
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap',
    fontSize: '14px',
    fontWeight: 600
  },
  listItem: {
    cursor: 'pointer',
    '&:hover > .menu-item, &:hover svg': {
      color: theme.palette.secondary.dark
    },
    '&.menu-item--no-dropdown': {
      paddingRight: 0
    },
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  listItemIcon: {
    minWidth: 'auto'
  },
  closeIcon: {
    justifyContent: 'flex-end',
    cursor: 'pointer'
  },
  menu: {
    display: 'flex'
  },
  menuItem: {
    marginRight: theme.spacing(8),
    '&:last-child': {
      marginRight: 0
    }
  },
  menuGroupItem: {
    paddingTop: 0
  },
  menuGroupTitle: {
    textTransform: 'uppercase'
  },
  divider: {
    width: '100%'
  }
}));

const SidebarNav = props => {
  const { pages, onClose, className, ...rest } = props;
  const classes = useStyles();

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      <ListItem className={classes.closeIcon} onClick={() => onClose()}>
        <ListItemIcon className={classes.listItemIcon}>
          <CloseIcon fontSize="small" />
        </ListItemIcon>
      </ListItem>
      <Link to="/guestlist"><ListItem
        className={clsx(
          classes.listItem
        )}
      >
        <Typography
          variant="body1"
          color="textPrimary"
          className={clsx(classes.listItemText, 'menu-item')}
        >
          Home
        </Typography>
      </ListItem>
      </Link>
      <Link to="/guestlist/features">
        <ListItem
          className={clsx(
            classes.listItem
          )}
        >
          <Typography
            variant="body1"
            color="textPrimary"
            className={clsx(classes.listItemText, 'menu-item')}
          >
            Features
          </Typography>
        </ListItem>
      </Link>
      <Link to="/guestlist/pricing"><ListItem
        className={clsx(
          classes.listItem
        )}
      >
        <Typography
          variant="body1"
          color="textPrimary"
          className={clsx(classes.listItemText, 'menu-item')}
        >
          Pricing
        </Typography>
      </ListItem>
      </Link>
      <Link to="/guestlist/support">
        <ListItem
          className={clsx(
            classes.listItem
          )}
        >
          <Typography
            variant="body1"
            color="textPrimary"
            className={clsx(classes.listItemText, 'menu-item')}
          >
            Support
          </Typography>
        </ListItem>
      </Link>
      <ListItem className={classes.listItem}>
        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          component="a"
          target="blank"
          href="https://guestlist-backoffice.qeerio.com/authentication/login"
        >
          Log in
        </Button>
      </ListItem>
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object,
  onClose: PropTypes.func
};

export default SidebarNav;
