export const services = [
  {
    icon: 'fas fa-wifi',
    title: 'Offline mode',
    description:
      'Works seamlessly without wifi. Qeerio stores all your offline actions and synchronizes with the cloud when it goes back online'
  },
  {
    icon: 'fas fa-palette',
    title: 'Branding',
    description:
      'Immerse your guests from the get-go. Customize Qeerio to match your event and brand colors'
  },
  {
    icon: 'fas fa-ban',
    title: 'Permissions',
    description:
      'Make sure nobody deletes your guest lists by assigning permissions'
  },
  {
    icon: 'fas fa-qrcode',
    title: 'QR code',
    description:
      'Speed up check-in by scanning guests personal QR code'
  },
  {
    icon: 'fas fa-cloud-upload-alt',
    title: 'Cloud synchronization',
    description:
      'All your event and guest information are safely stored on the cloud'
  },
  {
    icon: 'fas fa-file-import',
    title: 'Excel import',
    description:
      'Import thousand of guests instantly using excel sheets'
  },
  {
    icon: 'fas fa-file-export',
    title: 'Check-in report',
    description:
      'Instantly generate a report of your event check-in',
  },
  {
    icon: 'fas fa-sync-alt',
    title: 'Real time update',
    description:
      'Check-in updates in real time between all devices'
  },
  {
    icon: 'fas fa-lock',
    title: 'GDPR',
    description:
      'Built to protect your data. Qeerio is GDPR at its core'
  }
];
