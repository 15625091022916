import React from 'react';
import { Divider } from '@material-ui/core';
import { Section } from 'components/organisms';
import { Hero, Services } from './components';

import { services } from './data';

const PortfolioPage = () => (
  <div>
    <Hero />
    <Section>
      <Services data={services} />
    </Section>
    <Divider />
  </div>
);

export default PortfolioPage;
